import * as React from 'react'
import Button from '@material-ui/core/Button';
import {TextField,InputLabel,TextareaAutosize,Box } from '@material-ui/core';
import {Grid,Paper,Typography} from '@material-ui/core';

import {Container} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {format} from 'date-fns';
import {ja} from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';

import useRouter from '../utils/useRouterUtils';

// API Gatewayを呼び出すクラス
import * as cloudwatchMetricsService from '../components/cloudwatchMetricsServiceWorker';
import * as ecsServicesService from '../components/ecsServicesServiceWorker';
import * as measuresResultsService from '../components/measuresResultsServiceWorker';
import * as memosService from '../components/memosServiceWorker';
import * as templatesService from '../components/templatesServiceWorker';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

/** サービス呼出しテスト */
function ApiCallTest(props) {
  const classes = useStyles();
  const router  = useRouter();
  const [response, setResponse] = React.useState('');

  /** 検索 */
  const handleButton1Click = (event) => {
    console.log(event);
    if(typeof args === 'undefined') {
    }
    // メモ1件取得
    var date = '20191220';
    memosService.getMemos(date)
      .then(data => {
            // 非同期で呼ばれるため
            var result = [];
            if(Array.isArray(data)){
              // 配列
              result.push(data);
            } else{
              // 配列以外
              result = data;
            }

            var json = JSON.stringify(result);

            // 応答結果をuseStateにセット
            setResponse(json);
            console.log(json);
        });
  };

  /** 更新 */
  const handleButton2Click = (event) => {
    console.log(event);
    if(typeof args === 'undefined') {
    }

    // 更新
    var date = new Date();
    var dateString = format(date, 'yyyy/MM/dd (EEE) HH:mm:ss', {locale: ja});
    var memo = {TargetDate:20191220,Memo:"cccccccccccccccc" + ":" + dateString};
    var json = JSON.stringify(memo);
    console.log(json);

    memosService.modifyMemos(memo)
      .then(data => {
            // 非同期で呼ばれるため
            var result = [];
            if(Array.isArray(data)){
              // 配列
              result.push(data);
            } else{
              // 配列以外
              result = data;
            }

            // 応答結果をuseStateにセット
            var json = JSON.stringify(result);
            setResponse(json);
            console.log(JSON.stringify(result)) ;
      });
  };

  /** 追加 */
  const handleButton3Click = (event) => {
    console.log(event);
    if(typeof args === 'undefined') {
    }

// 追加
var memo = {TargetDate:20200101,Memo:"testtest\\n test"};
var json = JSON.stringify(memo);
console.log(json);
memosService.addMemos(memo)
  .then(data => {
      // 非同期で呼ばれるため
      var result = [];
      if(Array.isArray(data)){
        // 配列
        result.push(data);
      } else{
        // 配列以外
        result = data;
      }

      // 応答結果をuseStateにセット
      var json = JSON.stringify(result);
      setResponse(json);
      console.log(JSON.stringify(result)) ;
  });
  };

  /** 削除 */
  const handleButton4Click = (event) => {
    console.log(event);
    if(typeof args === 'undefined') {
    }

    // 削除
    var date = '20200101';
    memosService.removeMemos(date)
      .then(data => {
          // 非同期で呼ばれるため
          var result = [];
          if(Array.isArray(data)){
            // 配列
            result.push(data);
          } else{
            // 配列以外
            result = data;
          }

          // 応答結果をuseStateにセット
          var json = JSON.stringify(result);
          setResponse(json);
          console.log(JSON.stringify(result)) ;
        });
  };

  return (
    <Container className={classes.root}>
      <Grid container direction="column">
        <Grid item xs={12}>
          <Typography>
          サービス呼出しテスト
          </Typography>
        </Grid>
        <Grid>
        {process.env.REACT_APP_URL_MEMOS}
        </Grid>
        <Grid item xs={12}>
          <TextareaAutosize value={response}></TextareaAutosize>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={2}>
              <Button variant="contained" color="primary"
                  onClick={props => handleButton1Click(props)}
              >
                メモ1件検索実行
            </Button>
          </Grid>
          <Grid item xs={2}>
              <Button variant="contained" color="primary"
                  onClick={props => handleButton2Click(props)}
              >
                メモ更新
            </Button>
          </Grid>
          <Grid item xs={2}>
              <Button variant="contained" color="primary"
                  onClick={props => handleButton3Click(props)}
              >
                メモ追加
            </Button>
          </Grid>
          <Grid item xs={2}>
              <Button variant="contained" color="primary"
                  onClick={props => handleButton4Click(props)}
              >
                メモ削除
            </Button>
          </Grid>
        </Grid>
      </Grid>
     </Container>
  );
}

export default ApiCallTest;
