import * as React from 'react'
// import { render } from 'react-dom'
//import { Redirect } from 'react-dom'
//import * as  ReactDOM from 'react-dom'
///import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import FormControl  from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Container} from '@material-ui/core';
//import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
//import queryString from 'query-string';

import useRouter from '../utils/useRouterUtils';
import * as memosService from '../components/memosServiceWorker';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRow: {
    hover :{backgroundColor: "skyblue"  },
  },
  layout: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  divider:{
  },
  dividerDouble:{
      borderTop: '3px double #bbb',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
}));

/** ログイン */
function Login(props) {
  const classes = useStyles();
  const router  = useRouter();
  const [userId, setUserId] = React.useState('');
  const [password, setPassword] = React.useState('');

  function handleLoginClick(props) {
    if(typeof args === 'undefined') {
    }

    // TODO: ログイン処理を呼ぶ
    console.log(props);
    router.push('/Menu');
  }

  return (
    <Container className={classes.root}>
      <Typography variant="h5" component="h3">
        ログイン
        </Typography>
      <FormControl>
          <Box>ログインID</Box>
          <TextField
            variant="outlined"
            margin="dense"
            required
            fullWidth
            id="userId"
            name="userId"
            value={userId}
            autoFocus
              onChange={event => {
                setUserId( event.target.value);
              }}
          />
          <Box>パスワード</Box>
          <TextField
            variant="outlined"
            margin="dense"
            required
            fullWidth
            name="password"
            value={password}
            type="password"
            id="password"
              onChange={event => {
                setPassword( event.target.value);
              }}
          />
          <Button variant="contained" color="primary"
                onClick={props => handleLoginClick(props)}
            >
            ログイン
              </Button>
      </FormControl>
    </Container>
  );
}

export default Login
