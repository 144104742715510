import * as React from 'react'
import { Redirect } from 'react-dom'
//import { BrowserRouter, Switch, Route,  useLocation } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Container} from '@material-ui/core';
import {FormControl} from '@material-ui/core';

import useRouter from '../utils/useRouterUtils';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRow: {
    hover :{backgroundColor: "skyblue"  },
  },
  layout: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  divider:{
  },
  dividerDouble:{
      borderTop: '3px double #bbb',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
}));


function Menu(props){
  const classes = useStyles();
  const router = useRouter();

  const handleMenu1Close = () => {
    router.push('/TemplateMaintenance');
  };

  const handleMenu2Close = () => {
    router.push('/CalendarDailySituationList');
  };

  return (
    <Container className={classes.root}>
      <Typography variant="h5" component="h3">
        メニュー
        </Typography>
      <FormControl>
    <React.Fragment>
          <Link onClick={ handleMenu1Close }>施策テンプレート保守</Link>
          <Link onClick={ handleMenu2Close }>施策カレンダー</Link>
        </React.Fragment>
      </FormControl>
    </Container>
  )
};

export default Menu;
