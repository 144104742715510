import axios from "axios";
//import queryString from 'query-string';

// メモ

/** 全件取得
 *
  import * as memosService from '../components/memosServiceWorker';
       memosService.getMemos()
        .then(data => {
            var result = [];
            if(Array.isArray(data)){
              result.push(data);
            } else{
              result = data;
            }

          console.log(JSON.stringify(result)) ;
        });
*/
export async function getAllMemos(){
  var url = process.env.REACT_APP_URL_MEMOS;
  console.log("url:"+ url);
  const result = await axios.get(url)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
      return error;
    });
    console.log(result);
  return result;
}

/** １件取得
 *
  import * as memosService from '../components/memosServiceWorker';
       memosService.getMemos(date)
        .then(data => {
            var result = [];
            if(Array.isArray(data)){
              result.push(data);
            } else{
              result = data;
            }

          console.log(JSON.stringify(result)) ;
        });
　*/
export async function getMemos(date){
  var params = '/' + date;
  ///var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  var url = process.env.REACT_APP_URL_MEMOS  + params;
  console.log("url:"+ url);
  const result = await axios.get(url)
    .then(response => {
      if(response.data === null){
        return {"TargetDate":date,"Memo":""};
      }
      return response.data;
    })
    .catch(error => {
      console.log(error);
      return error;
    });
    console.log(result);
  return result;
}

/** 追加
 *
  import * as memosService from '../components/memosServiceWorker';
    var memo = {TargetDate:20200101,Memo:"testtest\\ntest"};
    var json = JSON.stringify(memo);
    console.log(json);
    memosService.addMemos(memo)
    .then(data => {
        // 非同期で呼ばれるため
        var result = [];
        if(Array.isArray(data)){
          result.push(data);
        } else{
          result = data;
        }
 */
export async function addMemos(memo){
  var params = '';
  var url = process.env.REACT_APP_URL_MEMOS  + params;
  var data = memo;
  console.log("url:"+ url);
  const result = await axios.post(url,data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
      return error;
    });
    console.log(result);
  return result;
}

/** 更新
 *
  import * as memosService from '../components/memosServiceWorker';
      var memo = {TargetDate:20191220,Memo:"AccccccccccccccccA"};
    //var obj = JSON.parse(memo);
    var json = JSON.stringify(memo);
    console.log(json);
    memosService.modifyMemos(memo)
    .then(data => {
        var result = [];
        if(Array.isArray(data)){
          result.push(data);
        } else{
          result = data;
        }
      console.log(JSON.stringify(result)) ;
    });
*/
export async function modifyMemos(memo){
  var params = '/' + memo.TargetDate;
  var url = process.env.REACT_APP_URL_MEMOS  + params;
  var data = memo;
  const result = await axios.put(url,data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
      return error;
    });
    console.log(result);
  return result;
}

/** 削除
 *
  import * as memosService from '../components/memosServiceWorker';
    var date = '20200101';
    memosService.removeMemos(date)
        .then(data => {
            var result = [];
            if(Array.isArray(data)){
              result.push(data);
            } else{
              result = data;
            }
          console.log(JSON.stringify(result)) ;
        });
 */
export async function removeMemos(date){
  var params = '/' + date;
  var url = process.env.REACT_APP_URL_MEMOS  + params;
  const result = await axios
    .delete(url)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
      return error;
    });
    console.log(result);
  return result;
}
