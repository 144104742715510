import React from 'react'
// import { render } from 'react-dom'
import { Switch, Route, useLocation } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'

import Login from './views/Login';
import Menu from './views/Menu';
import TemplateMaintenance from './views/TemplateMaintenance';
import CalendarDailySituationList from './views/CalendarDailySituationList';
import ApiCallTest from './views/ApiCallTest';

function App() {
  const location = useLocation();
  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0, transform: 'translate3d(100%,100%,100%)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0%,0%)' },
    leave: { opacity: 0, transform: 'translate3d(0%,0%,0%)' },
  });
  // Routeのpath="/"のコンポーネントを変更するとその画面がデフォルト画面となる（
  return transitions.map(({ item: location, props, key }) => (
      <animated.div key={key} style={props}>
        <Switch location={location}>
          <Route path="/" exact component={Login} />
          <Route path="/Login" component={Login} />
          <Route path="/Menu" component={Menu} />
          <Route path="/TemplateMaintenance" component={TemplateMaintenance} />
          <Route path="/CalendarDailySituationList" component={CalendarDailySituationList} />
          <Route path="/ApiCallTest" component={ApiCallTest} />
        </Switch>
      </animated.div>
  ))
}

export default App;
