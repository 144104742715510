import * as React from 'react'
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {TextField } from '@material-ui/core';

import Button from '@material-ui/core/Button';
//import {IconButton} from '@material-ui/core';
import {CircularProgress} from '@material-ui/core';
import {Grid,Paper,Typography} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import {Container}from '@material-ui/core';
//import MenuIcon from '@material-ui/icons/Menu';
//import {Table,TableBody,TableCell,TableHead,TableRow,TableSortLabel,TableContainer} from '@material-ui/core';
import {Select,MenuItem,FormControl} from '@material-ui/core';
//import AppBar from '@material-ui/core/AppBar';
import Menu from '@material-ui/core/Menu';

import MaterialTable from 'material-table';

import { ThemeProvider, useTheme, createMuiTheme } from '@material-ui/core/styles';

import useRouter from '../utils/useRouterUtils';

// Templateサービス
import * as templatesService from '../components/templatesServiceWorker';

// ECSサービス
import * as ecsServicesService from '../components/ecsServicesServiceWorker';

//#region ▽テンプレート一覧

const options = [
  {
    header:false,
    search:false,
    toolbar:false,
    toolbarButtonAlignment: 'left' ,
    paging:false,
    padding : 'dense'
  }
];
const columns = [
  { title: "テンプレート一覧", field: "TemplateName" ,editComponent: props => (
    <TextField
      type="text"
      defaultValue={props.value}
      onChange={e => props.onChange(e.target.value)}
    />
  )
  },
  { title: "", field: "Id",hidden :true },
];

/** テンプレート一覧 */
function TemplateTable(props) {
  const { classes,options,columns,datas,detailDatas,ecsServiceDatas } = props;
  const detailColumns = [
    { title: "コンテナサービス識別子", field: "EcsServiceName" ,
    editComponent: props => (
      <Select
        labelId="service-select-label"
        id="service-select"
        defaultValue={props.value}
        // defaultValue={row.EcsServiceName}
        onChange={e => props.onChange(e.target.value)}
        // onChange={event => handleEcsServiceChange(event,row,index)}
        >
          {state.ecsServiceData.map((ecsService,index) => (
            <MenuItem id={ecsService.Id}
              value={ecsService.EcsServiceName}>{ecsService.EcsServiceName}
            </MenuItem>
          ))}
      </Select>
    )},
    { title: "起動数", field: "TaskNum" ,editComponent: props => (
      <TextField
        type="number"
        defaultValue={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    )},
    { title: "EcsClusterName", field: "EcsClusterName",hidden :true },
    { title: "Id", field: "Id",hidden :true },
  ];

  const [state, setState] = React.useState({
    options: [...options],
    columns: [...columns],
    detailColumns: [...detailColumns],
    data: [...datas],
    detailData: [...detailDatas],
    ecsServiceData: [...ecsServiceDatas],
    selectedRow : null,
    selectIndex : -1,
    selectDetailIndex : -1,
  });
  const [isChanged, setIsChanged] = React.useState(false);
  // const [selectIndex, setSelectIndex] = React.useState(0);
  // const [selectDetailIndex, setSelectDetailIndex] = React.useState(0);
  const [errorMessage, setErrorMessage] = React.useState('');

  /** 登録 */
  const handleResistClick = (event,detailData) => {
    if(detailData !== undefined){
      // if(isChanged){
        templatesService.modifyTempletes(detailData.Id,detailData)
        .then(data => {
          // 非同期処理
          if(data === ""){
            // 正常応答
              // 正常応答
              alert("更新が完了しました。" + detailData.TemplateName);
            }
        });
      // }
   }
　}

  /** 閉じる */
  const handleCancelClick = (event,detailData) => {
    var i = state.data.indexOf(detailData);
    tableRef.current.onToggleDetailPanel([i], rowData => <div>{rowData.name}</div>);
  }

  const isExistEcsService = (item,ecsServiceName) =>{
    return (item.EcsServiceName === ecsServiceName ? true :false);
  };

  /** ECSサービスを変更 */
  const handleEcsServiceChange = (event, row, index) => {
  　//console.log('value:'+ event.target.value +'index:'+ index);
    var res = ecsServiceDatas.filter(item => isExistEcsService(item,event.target.value));
    if(res.length >= 1){
      var item = res[0];
      row.Id= item.Id;
      row.TaskNum= item.TaskNum;
      row.EcsClusterName= item.EcsClusterName;
      row.EcsServiceName = item.EcsServiceName;
      setIsChanged(true);
    }
  };

  /** タスク数 */
  const handleTaskNumchange = (event, row, index) => {
    row.TaskNum = event.target.value;
    setIsChanged(true);
  }

  /** テンプレート名 */
  const handleTemplateChange = (event,detailData) => {
    if(event != null){
      detailData.TemplateName = event.target.value;
      setIsChanged(true);
    }
  };
  const handleSelectionChange = (event) => {
    if(event != null){

    }
  };
  const tableRef = React.useRef();

  React.useEffect(() => {
    return () => {
      // クリーンアップ
    };
  });

  return (
    <Grid>
      <Divider />
        <Typography variant="h6" component="h3" gutterBottom>
          =テンプレート一覧=
        </Typography>
      <Divider />
    <MaterialTable
        id="main-table"
        tableRef={tableRef}
        options={{
          header:state.options.header,
          search:false,
          toolbarButtonAlignment:'left',
          paging:state.options.paging,
          padding:state.options.padding,
        }}
        onSelectionChange={(event)=> handleSelectionChange}
        title=""
        columns={state.columns}
        data={state.data}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        detailPanel={(detailData) => {
          state.selectIndex = detailData.tableData.id;
          return (
            <Paper className={classes.paper}>
              <Divider />
              <Typography variant="h6" component="h3">
                =アプリケーション構成設定=
              </Typography>
              <TextField
                id={`template-name-${detailData.tableData.id}`}
                type="text"
                defaultValue={detailData.TemplateName}
                onChange={(event) => { handleTemplateChange(event,detailData)}} >
              </TextField>
              <Divider />
              <MaterialTable
                id="detail-table"
                title=""
                options={{
                  header:true,
                  search:false,
                  toolbarButtonAlignment:'left',
                  paging:state.options.paging,
                  padding:state.options.padding,
                }}
                columns={state.detailColumns}
                data={detailData.EcsServiceList}
                localization={{
                  header: {
                    actions: "",
                  },
                  body: {
                    editTooltip: "更新",
                    deleteTooltip: "削除",
                    addTooltip: "追加",
                    emptyDataSourceMessage:"表示するレコードが存在しません",
                    editRow: {
                      deleteText: "削除しますがよろしいですか？",
                      cancelTooltip: "取消",
                      saveTooltip: "確定",
                    }
                  }
                }}
                editable={{
                  onRowAdd: (newData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        setState((prevState) => {

                          const data = [...prevState.data];
                          const detailData = [...data[prevState.selectIndex].EcsServiceList];
                          const ecsServiceName = newData.EcsServiceName;
                          var res = ecsServiceDatas.filter(item => isExistEcsService(item,ecsServiceName));
                          if(res.length >= 1){
                            // TaskNum以外をセットする。
                            var item = res[0];
                            newData.Id = item.Id;
                            newData.EcsClusterName = item.EcsClusterName;
                            setIsChanged(true);
                          }

                          detailData.push(newData);
                          prevState.data[prevState.selectIndex].EcsServiceList = detailData;
                          return { ...prevState, detailData };
                        });
                      }, process.env.REACT_APP_TIMEOUT);
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise(resolve => {
                      setTimeout(() => {
                        resolve();
                        if (oldData) {
                          setState(prevState => {
                            const data = [...prevState.data];
                            const detailData = [...data[prevState.selectIndex].EcsServiceList];
                            const index = prevState.data[prevState.selectIndex].EcsServiceList.indexOf(oldData);
                            detailData[index] = newData;
                            prevState.data[prevState.selectIndex].EcsServiceList = detailData;
                            return { ...prevState, detailData };
                          });
                        }
                      }, process.env.REACT_APP_TIMEOUT);
                    }),
                  onRowDelete: oldData =>
                    new Promise(resolve => {
                      setTimeout(() => {
                        resolve();
                        setState(prevState => {
                          const data = [...prevState.data];
                          const detailData = [...data[prevState.selectIndex].EcsServiceList];
                          const index = prevState.data[prevState.selectIndex].EcsServiceList.indexOf(oldData);
                          detailData.splice(index, 1);
                          prevState.data[prevState.selectIndex].EcsServiceList = detailData;
                          return { ...prevState, detailData };
                        });
                      }, process.env.REACT_APP_TIMEOUT);
                    })
                }}
              >
              </MaterialTable>
              <div className={classes.layout}>
                <Button variant="outlined" color="primary"
                  onClick={(event) =>  {handleResistClick(event,detailData)}}
                  >
                登録</Button>
                <Button variant="outlined" color="secondary"
                  onClick={(event) => { handleCancelClick(event,detailData)}}>
                  閉じる</Button>
              </div>
          </Paper>
        )
        }}
        localization={{
          header: {
            actions: "",
          },
          body: {
            editTooltip: "更新",
            deleteTooltip: "削除",
            addTooltip: "追加",
            emptyDataSourceMessage:"表示するレコードが存在しません",
            editRow: {
              deleteText: "削除しますがよろしいですか？",
              cancelTooltip: "取消",
              saveTooltip: "確定",
            }
          }
        }}
        editable={{
          onRowAdd: newData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                setState(prevState => {
                  const data = [...prevState.data];

                  // 詳細を追加
                  newData.EcsServiceList = [];
                  newData.EcsServiceList.push(...ecsServiceDatas);
                  data.push(newData);

                  // 追加処理
                  // var json = JSON.stringify(newData);
                  // console.log("add:" + newData);
                  templatesService.addTempletes(newData)
                    .then(res => {
                      // 非同期処理
                      if(res !== ""){
                        // IDを反映
                        data[data.length -1].Id = res;
                        // 正常応答
                        alert("追加が完了しました。" + newData.TemplateName);
                      }
                    });
                    return { ...prevState, data };
                  });
              }, process.env.REACT_APP_TIMEOUT);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                setState(prevState => {
                  const data = [...prevState.data];
                  // 削除処理
                  templatesService.removeTempletes(oldData.Id)
                    .then(data => {
                      // 非同期処理
                      if(data === ""){
                        // 正常応答
                        alert("削除が完了しました。" + oldData.TemplateName);
                      }
                    });
                  data.splice(data.indexOf(oldData), 1);
                  return { ...prevState, data };
                });
              }, process.env.REACT_APP_TIMEOUT);
            })
        }}
    >
    </MaterialTable>
    {/* エラーメッセージ */}
    <Grid className={classes.errorText}>{errorMessage}</Grid>
  </Grid>
  )};

  TemplateTable.propTypes = {
    classes: PropTypes.object.isRequired,
    options:PropTypes.array.isRequired,
    columns:PropTypes.object,
    datas:PropTypes.object.isRequired,
    ecsServiceDatas:PropTypes.array,
  };

//#endregion △テンプレート一覧

//#region ▽アプリケーション構成設定

//#endregion △アプリケーション構成設定
/** スタイル定義 */
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexGrow: 1,
    // backgroundColor: theme.palette.background.default,
    // color: theme.palette.text.primary,
    // backgroundColor: '#3f3f3f',
    // color: '#ffffff',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRow: {
    // hover :{backgroundColor: "skyblue"  },
  },
  layout: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  divider:{
  },
  dividerDouble:{
      borderTop: '3px double #bbb',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  errorText: {
    margin: theme.spacing(1),
    color: '#ff0000',
  },
}));

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

//#region TemplateMaintenance
/** 施策テンプレート保守メイン処理 */
function TemplateMaintenance() {
  //#region 定数
  const classes = useStyles();
  const router = useRouter();
  const [selected, setSelected] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  //#endregion 定数

  //#region 変数

  // テンプレート
  let isLoadingTemplates = true;
  const [templateRows, setTemplateRows] = React.useState([]);

  // ECSサービス
  let isLoadingEcsServices = true;
  const [ecsServiceRows, setEcsServiceRows] = React.useState([]);

  //#endregion 変数

  const getAllTemplates = () => {
    templatesService.getAllTempletes()
    .then(data => {
        // 非同期で呼ばれるため
        var result = [];
        if(Array.isArray(data)){
          // 配列
          result.push(data);
        } else{
          // 配列以外
          result = data;
        }

        // 応答結果をuseStateにセット
        if(result.isAxiosError === true){
          // 通信エラー
          var rowData = [];
          rowData.EcsServiceList = [];
          setTemplateRows([...rowData]);
          isLoadingTemplates = false;
          if(!isLoadingEcsServices){
            setIsLoading(false);
          }
          return;
        }

        var json = JSON.stringify(result[0]);
        // console.log(json);
        var response = JSON.parse(json);

        // テンプレートをセットする
        setTemplateRows([...response]);

        isLoadingTemplates = false;
        if(!isLoadingEcsServices){
          setIsLoading(false);
        }
      // console.log(isLoadingTemplates);
    });
  };
  const getAllEcsServices = () => {
    ecsServicesService.getAllEcsServices()
    .then(data => {
        // 非同期で呼ばれるため
        var result = [];
        if(Array.isArray(data)){
          // 配列
          result.push(data);
        } else{
          // 配列以外
          result = data;
        }

        // 応答結果をuseStateにセット
        var json = JSON.stringify(result[0]);
        // console.log(json);
        var response = JSON.parse(json);

        // ECSサービスをセットする
        setEcsServiceRows([...response]);
        isLoadingEcsServices = false;
      if(!isLoadingTemplates){
        setIsLoading(false);
      }
      // console.log(isLoadingEcsServices);
    });
  };

  //#region イベント
  React.useEffect(() => {
    // 初期処理
    if(isLoading) {
        // テンプレート
        if(isLoadingTemplates){
          getAllTemplates();
        }

        // ECSサービス
        if(isLoadingEcsServices){
          getAllEcsServices();
      }
    }

    ///doAsyncInitializeTempletesCallback(500, () => console.log("done"));
    return () => {
      // クリーンアップ
    };
  },[isLoading]);

  const  selectedListItem = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  // const handleClick = (event, name) => {
  //   selectedListItem(event,name);
  // };
  // const handleEditClick = (event, name) => {
  //   selectedListItem(event,name);
  //   const selectedIndex = selected.indexOf(name);
  //   setSelected([]);
  // }
  // const handleDeleteClick = (event, name) => {
  //   selectedListItem(event,name);
  //   const selectedIndex = selected.indexOf(name);
  //   setSelected([]);
  // }

  const isSelected = name => selected.indexOf(name) !== -1;

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenu1Close = () => {
    setAnchorEl(null);
    router.push('/TemplateMaintenance');
  };

  const handleMenu2Close = () => {
    setAnchorEl(null);
    router.push('/CalendarDailySituationList');
  };
  //#endregion イベント

  return (
    <ThemeProvider theme={lightTheme}>
    <Container className={classes.root}>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
        メニュー
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        >
        <MenuItem onClick={handleMenu1Close}>施策テンプレート保守</MenuItem>
        <MenuItem onClick={handleMenu2Close}>施策カレンダー</MenuItem>
      </Menu>
      {isLoading ?
        (
          // 処理中
          <CircularProgress />
        )
        :
        (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5" component="h3">
                          施策テンプレート保守
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TemplateTable
                classes={classes}
                options={options}
                columns={columns}
                datas={templateRows}
                //detailColumns={detailColumns}
                detailDatas={templateRows.length === 0 ? [] : templateRows[0].EcsServiceList}
                ecsServiceDatas={ecsServiceRows}
                />
                  <Divider className={classes.dividerDouble} />
            </Grid>
          </Grid>
        )
      }
  </Container>
  </ThemeProvider>
  );
}

export default TemplateMaintenance;
//#endregion TemplateMaintenance
