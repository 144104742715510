import * as React from 'react'
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';

import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { TextField, TextareaAutosize, Box, Input, InputLabel } from '@material-ui/core';
import { Grid, Paper, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { Container, FormControl } from '@material-ui/core';
import { Select, MenuItem } from '@material-ui/core';
import { List, ListItem, ListItemText, } from '@material-ui/core';
import { IconButton, SvgIcon } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, TableContainer } from '@material-ui/core';

import Menu from '@material-ui/core/Menu';
//import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
//import MenuIcon from '@material-ui/icons/Menu';
//import {ExpandMore} from '@material-ui/icons';
import { DatePicker, TimePicker, DateTimePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import MaterialTable, { MTableBodyRow, MTableEditRow } from 'material-table';

import { ThemeProvider, useTheme, createMuiTheme } from '@material-ui/core/styles';

import useRouter from '../utils/useRouterUtils';

// Cloudwatchサービス
import * as cloudwatchMetricsService from '../components/cloudwatchMetricsServiceWorker';

// ECSサービス
import * as ecsServicesService from '../components/ecsServicesServiceWorker';

// 施策実績サービス
import * as measuresResultsService from '../components/measuresResultsServiceWorker';

// メモサービス
import * as memosService from '../components/memosServiceWorker';

// Templateサービス
import * as templatesService from '../components/templatesServiceWorker';

var currentCalendar = '';

const detailColumns = [
  { id: 'EcsServiceName', numeric: false, disablePadding: true, label: 'コンテナサービス識別子' },
  { id: 'TaskNum', numeric: true, disablePadding: true, label: '起動数' },
  { id: 'EcsClusterName', numeric: false, disablePadding: false, label: '' },
  { id: 'Id', numeric: false, disablePadding: false, label: '' },
];

/** 年月リストの配列を生成 */
function createCalendarMonth(today, i) {
  var now = new Date(today.getTime());
  var month = now.getMonth();
  now.setDate(1);
  now.setMonth(today.getMonth() + i);
  var year = now.getFullYear();
  var month = now.getMonth() + 1; // JSでは月は0からなので+1
  var day = 1;
  var displayString = year + "年" + (month) + "月";
  now.setHours(0, 0, 0, 0);
  var workDate = now;
  var isThisMonth = (i === 0) ? true : false;
  if (isThisMonth === true) { currentCalendar = displayString; }
  return { workDate, year, month, day, displayString, isThisMonth };
}

createCalendarMonth.propTypes = {
  today: PropTypes.any.isRequired,
  i: PropTypes.number.isRequired,
};

/**
 * 指定した日付を元に範囲の年月のコレクションを作成する（-6カ月+12カ月）
 * @param {*} today
 */
function createCalendarMonthList(today) {
  var workday = new Date(today.getTime());
  workday.setHours(0, 0, 0, 0);
  var rows = [];
  for (var i = -6; i < 12; i++) {
    var item = createCalendarMonth(workday, i);
    rows.push(JSON.parse(JSON.stringify(item)));
  }
  return rows;
}

/** 対象年月リスト */
var calendarYmRows = [
  ...createCalendarMonthList(new Date()),
];

/** 対象の日付のカレンダーリスト（年月日のコレクション）を生成する */
function createDateList(today) {
  var workday = new Date(today.getTime());
  workday.setHours(0, 0, 0, 0);
  var rows = [];
  for (var i = 1; i < 31 + 1; i++) {
    var item = createDate(workday, i);
    rows.push(JSON.parse(JSON.stringify(item)));
  }
  return rows;
}

/** 1か月分の配列を生成 */
function createDate(today, i) {
  var firstday = new Date(today.getTime());
  var lastday = new Date(today.getTime());
  var workday = new Date(today.getTime());
  var now = new Date();
  now.setHours(0, 0, 0, 0);
  firstday.setDate(1);
  lastday.setMonth(today.getMonth() + 1);
  lastday.setDate(0);
  workday.setDate(i);
  var id = i;
  var workDate = workday.getDate() === i ? workday : '';
  var isToday = (workday === today) ? true : false;
  var isFutureday = (workday >= now) ? true : false;
  var dateString = workDate === '' ? '----------' : format(workDate, 'yyyy/MM/dd (EEE)', { locale: ja });
  return { id, workDate, dateString, isToday, isFutureday };
}

/** メモを生成 */
function createMemo() {
  var targetDate = new Date();
  var memo = "";
  return { targetDate, memo };
}

var calendarRows = [
  ...createDateList(new Date())
];

/** スタイル定義 */
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // backgroundColor: theme.palette.background.default,
    // color: theme.palette.text.primary,
    // backgroundColor: '#3f3f3f',
    // color: '#ffffff',
  },
  paper: {
    // width: '100%',
    // marginBottom: theme.spacing(1),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRow: {
    //hover :{backgroundColor: 'skyblue' },
  },
  layout: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  divider: {
  },
  dividerDouble: {
    borderTop: '3px double #bbb',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  list: {
    width: '100%',
    minWidth: 200,
    height: '100%',
    maxHeight: 800, overflow: 'auto',
    //backgroundColor: 'white'
  },
  listItem: {
  },
  listItemText: {
  },
  textArea: {
    margin: theme.spacing(1),
    width: '100%',
    minWidth: 200,
    maxHeight: 200, overflow: 'auto',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  iconImage: {
    width: 300,
    height: 300,
    padinng: 16,
  },
  errorText: {
    margin: theme.spacing(1),
    color: '#ff0000',
  },
  imageOver: {
    margin: '16px 16px 90px 16px',
    float: 'left',
    backgroundColor: 'white',

    transition: '0.35s',
    '&:hover': {
      position: 'relative',
      opacity: 1.0,
      zIndex: 2147483647,
      transform: 'scale(2.5, 2.5) translate(0px,10px)',
    }
  },
  imageOverEnd: {
    clear: 'both'
  }

}));

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

const options = [
  {
    header: false,
    search: false,
    toolbar: false,
    toolbarButtonAlignment: 'left',
    paging: false,
    padding: 'dense'
  }
];

/** ステータス */
const statusRows = [
  { key: 1, value: "ACTIVE", text: "確定" },
  { key: 2, value: "INACTIVE", text: "未確定" },
];


const formatDate = (date) => {
  var res = format(date, 'yyyy/MM/dd HH:mm:ss', { locale: ja });
  console.log(res);
  return res;
}

/** システム構成  */
function DailySituationTable(props) {
  const { classes, options, datas, detailColumns, detailDatas, templateDatas, ecsServiceDatas, workdate, isDetailChanged, isFutureday } = props;

  const handleDateTimeChange = (e, props) => {
    var d = format(e, 'yyyy/MM/dd HH:mm:ss', { locale: ja });
    props.onChange(d);
  };
  const columns = [
    {
      title: "MeasuresName", field: "MeasuresName",
      editComponent: props => (
        <TextField
          type="text"
          defaultValue={props.value}
          onChange={e => props.onChange(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )
    },
    // { title: "Id", field: "Id"},
    {
      title: "MeasuresStartDateTime", field: "MeasuresStartDateTime",//type: 'datetime' ,
      editComponent: props => (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
          <KeyboardDateTimePicker
            ampm={false}
            views={["year", "month", "date", "hours", "minutes"]}
            value={props.value !== undefined ? props.value : format(state.workDay, 'yyyy/MM/dd HH:mm:ss', { locale: ja })}
            minDate={new Date(state.workDay.getFullYear(), state.workDay.getMonth(), state.workDay.getDate() - 1)}
            maxDate={new Date(state.workDay.getFullYear(), state.workDay.getMonth(), state.workDay.getDate())}
            onChange={e => props.onChange(formatDate(e))}
            format="yyyy/MM/dd HH:mm"
          />
        </MuiPickersUtilsProvider>
      )
    },
    {
      title: "MeasuresEndDateTime", field: "MeasuresEndDateTime",//type: 'datetime' ,
      editComponent: props => (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
          <KeyboardDateTimePicker
            ampm={false}
            views={["year", "month", "date", "hours", "minutes"]}
            value={props.value !== undefined ? props.value : format(state.workDay, 'yyyy/MM/dd HH:mm:ss', { locale: ja })}
            minDate={new Date(state.workDay.getFullYear(), state.workDay.getMonth(), state.workDay.getDate())}
            // maxDate={new Date(state.workDay.getFullYear(),state.workDay.getMonth(),state.workDay.getDate()+1)}
            onChange={e => props.onChange(formatDate(e))}
            format="yyyy/MM/dd HH:mm"
          />
        </MuiPickersUtilsProvider>
      )
    },
    {
      title: "Status", field: "Status", lookup: { "ACTIVE": "確定", "INACTIVE": "未確定" },
      // editComponent: props => (
      //   <Select
      //     labelId="status-select-label"
      //     id="status-select"
      //     value={props.value}
      //     onChange={e => props.onChange(e.target.value)}
      //   >
      //     {statusRows.map((row) => {
      //       return (
      //         <MenuItem key={row.key} value={row.value}>
      //         {row.text}
      //       </MenuItem>
      //       );
      //   })}
      // </Select>
      //)
    },
  ];

  const [state, setState] = React.useState({
    options: [...options],
    columns: [...columns],
    data: [...datas],
    detailColumns: [...detailColumns],
    detailData: [...detailDatas],
    templateData: [...templateDatas],
    ecsServiceData: [...ecsServiceDatas],
    selectedRow: null,
    workDay: workdate,
    workDay: workdate,
    selectIndex: -1,
    selectDetailIndex: -1,
    isFutureday: isFutureday,
    //errorMessage : '',
    selectTemplate: ''
  });

  const [isChanged, setIsChanged] = React.useState(isDetailChanged);
  const [columnIndex, setSColumnIndex] = React.useState(-1);
  const [selectTemplate, setSelectTemplate] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const isExistEcsService = (item, event) => {
    return (item.EcsServiceName === event.target.value ? true : false);
  };

  const handleServiceChange = (event, row, index) => {
    // console.log('value:'+ event.target.value +'index:'+ index);
    var res = ecsServiceDatas.filter(item => isExistEcsService(item, event));
    if (res.length >= 1) {
      var item = res[0];
      row.Id = item.Id;
      row.TaskNum = item.TaskNum;
      row.EcsClusterName = item.EcsClusterName;
      row.EcsServiceName = item.EcsServiceName;
      setIsChanged(true);
    }
    ///setServicename(event.target.value);
  };

  /** タスク数を変更時 */
  const handleTaskNumchange = (event, row, index) => {
    row.TaskNum = event.target.value;
    setIsChanged(true);
  }

  /** テンプレートを変更時 */
  const handleTemplateChange = (event, detailData) => {
    var i = state.data.indexOf(detailData);
    if (event.target.value !== undefined) {
      setSelectTemplate(event.target.value);
      var res = state.templateData.filter(item => isExistTemplate(item, event.target.value));
      if (res.length >= 1) {
        detailData.EcsServiceList = [...res[0].EcsServiceList];
        setIsChanged(true);

        // 明細を閉じる
        // tableRef.current.onToggleDetailPanel([i], rowData => <div>{rowData.name}</div>);
      }
    }
  };

  /** 対象のテンプレートか判定する */
  const isExistTemplate = (item, template) => {
    if (item === undefined) {
      return false;
    }
    return (item.TemplateName === template ? true : false);
  };

  /** 確定 */
  const handleResistClick = (event, detailData) => {
    if (detailData !== undefined) {
      var rows = statusRows.filter(row => row.text === "確定");
      if (rows.length > 0) {
        var value = rows[0].value;
        detailData.Status = value;
      }

      setErrorMessage("");

      measuresResultsService.modifyMeasuresResults(detailData)
        .then(data => {
          // 非同期処理
          if (data !== undefined && data.response !== undefined && data.response.status !== undefined && data.response.status != 200) {
            // 正常応答以外
            setErrorMessage("エラーが発生しました。入力内容に問題があります。[" + data.response.statusText + data.message + "]");
          }
          else if (data === "") {
            // 正常応答
            alert("更新が完了しました。" + detailData.MeasuresName);
          }
        });

    }
  };

  /** 一時保存 */
  const handleSaveClick = (event, detailData) => {
    // 未確定に変更
    if (detailData !== undefined) {
      var rows = statusRows.filter(row => row.text === "未確定");
      if (rows.length > 0) {
        var value = rows[0].value;
        detailData.Status = value;
      }

      setErrorMessage("");

      // 更新処理
      measuresResultsService.modifyMeasuresResults(detailData)
        .then(data => {
          // 非同期処理
          if (data !== undefined && data.response !== undefined && data.response.status !== undefined && data.response.status != 200) {
            // 正常応答以外
            setErrorMessage("エラーが発生しました。入力内容に問題があります。[" + data.response.statusText + data.message + "]");
          }
          else if (data === "") {
            // 正常応答
            alert("更新が完了しました。" + detailData.MeasuresName);
          }

        });
    }
  };

  const isUpperValidateDate = (startDate, endDate) => {
    if (startDate >= endDate) {
      return true;
    }
    return false;
  };

  const tableRef = React.useRef();


  return (
    <Grid>
      <Divider />
      <Typography variant="h6" component="h3" gutterBottom>
        システム構成
        </Typography>
      <Divider />
      <MaterialTable
        id="main-table"
        tableRef={tableRef}
        options={{
          header: state.options.header,
          search: false,
          toolbarButtonAlignment: 'left',
          paging: state.options.paging,
          padding: state.options.padding,
        }}
        title=""
        columns={state.columns}
        data={state.data}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        components={{
          Row: props => (
            <Grid>
              <MTableBodyRow {...props} />
            </Grid>
          ),
          EditRow: props => (
            <Grid>
              <MTableEditRow {...props} />
            </Grid>
          ),
        }}
        detailPanel={(detailData) => {
          return (
            <Container>
              <Divider />
              <TextField type="text" autoFocus={false} readonly value="施策名："></TextField>
              <TextField
                type="text"
                id="measures-name-text"
                value={detailData.MeasuresName}>
                readonly
              </TextField>
              <Box width={500} display={
                // モードの切替 // todo:フラグを定義する
                isFutureday ?
                  // 表示
                  { md: 'inline-block' }
                  :
                  // 非表示
                  { md: 'none' }
              } m={1}>
                <TextField type="text" autoFocus={false} readonly value="テンプレート："></TextField>
                <Select
                  labelId="template-select-label"
                  id="template-select"
                  defaultValue={selectTemplate}
                  onChange={event => handleTemplateChange(event, detailData)}
                >
                  {state.templateData.map((row) => {
                    return (
                      <MenuItem key={row.Id} value={row.TemplateName}>
                        {row.TemplateName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>

              <Divider />
              <TableContainer component={Container} id="detail-table">
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>コンテナサービス識別子</TableCell>
                      <TableCell align="right">起動数</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {detailData.EcsServiceList.map((row, index) => (
                      <TableRow key={index} className={classes.tableRow}>
                        <TableCell component="th" scope="row">
                          {row.EcsServiceName}
                        </TableCell>
                        <TableCell align="right" scope="row">
                          <TextField
                            type="number"
                            //value={row.TaskNum}
                            defaultValue={row.TaskNum}
                            onChange={(event) => { handleTaskNumchange(event, row, index) }}
                            inputProps={
                              isFutureday ?
                                {
                                  style: { textAlign: "right" }, readOnly: false,
                                }
                                :
                                {
                                  style: { textAlign: "right" }, readOnly: true,
                                }
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box height={50} width="100%">
                <Box width={120} display={
                  // 編集モードの切替
                  isFutureday ?
                    // 表示
                    { md: 'none' }
                    // {md: 'inline-block' }
                    :
                    // 非表示
                    { md: 'none' }
                } m={1}>
                  <Button
                    variant="outlined" color="secondary"
                    onClick={(event) => { handleSaveClick(event, detailData) }}
                  >
                    一時保存</Button>
                </Box>
                <Box width={120} display={
                  // 編集モードの切替
                  isFutureday ?
                    // 表示
                    { md: 'inline-block' }
                    :
                    // 非表示
                    { md: 'none' }
                } m={1}>
                  <Button
                    variant="outlined" color="primary"
                    onClick={(event) => { handleResistClick(event, detailData) }}
                  >
                    確定</Button>
                </Box>
              </Box>
            </Container>
          )
        }}
        localization={{
          body: {
            editTooltip: "更新",
            deleteTooltip: "削除",
            addTooltip: "追加",
            emptyDataSourceMessage: "表示するレコードが存在しません",
            editRow: {
              deleteText: "削除しますがよろしいですか？",
              cancelTooltip: "取消",
              saveTooltip: "確定",
            }
          }
        }}
        editable={
          // 編集モードの切替
          isFutureday ?
            // 更新追加削除可
            {
              onRowAdd: newData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    setState(prevState => {
                      const data = [...prevState.data];

                      // 未設定の時は初期値をセット
                      if (newData.MeasuresStartDateTime === undefined || newData.MeasuresStartDateTime === null) {
                        var startDate = new Date(prevState.workDay);
                        startDate.setHours(7, 0, 0, 0);
                        var startDateTimeString = format(startDate, 'yyyy/MM/dd HH:mm:ss', { locale: ja });
                        newData.MeasuresStartDateTime = startDateTimeString;
                      }
                      if (newData.MeasuresEndDateTime === undefined || newData.MeasuresEndDateTime === null) {
                        var endDate = new Date(prevState.workDay);
                        endDate.setHours(19, 0, 0, 0);
                        var endDateTimeString = format(endDate, 'yyyy/MM/dd HH:mm:ss', { locale: ja });
                        newData.MeasuresEndDateTime = endDateTimeString;
                      }

                      var startDate = new Date(newData.MeasuresStartDateTime);
                      var endDate = new Date(newData.MeasuresEndDateTime);

                      if (isUpperValidateDate(startDate, endDate)) {

                      }

                      if (newData.Status === undefined || newData.Status === null) {
                        var status = statusRows.filter(row => row.text === "未確定");
                        if (status.length > 0) {
                          newData.Status = status[0].value;
                        }
                      }

                      // 詳細を追加
                      newData.EcsServiceList = [];
                      newData.EcsServiceList.push(...ecsServiceDatas);
                      data.push(newData);

                      setErrorMessage("");

                      // 追加処理
                      measuresResultsService.addMeasuresResults(newData)
                        .then(res => {
                          // 非同期処理
                          if (res !== "") {
                            // 異常応答
                            if (res !== undefined && res.response !== undefined && res.response.status !== undefined && res.response.status != 200) {
                              // 正常応答以外
                              setErrorMessage("エラーが発生しました。入力内容に問題があります。[" + res.response.statusText + res.message + "]");
                            }
                            else {
                              // IDを反映
                              if (!res.isAxiosError) {
                                data[data.length - 1].Id = res;
                                alert("追加が完了しました。" + newData.MeasuresName);
                              }
                            }
                          }
                        });

                      return { ...prevState, data };
                    });
                  }, process.env.REACT_APP_TIMEOUT);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    setState(prevState => {
                      const index = prevState.data.indexOf(oldData);
                      const data = [...prevState.data];
                      // 登録用に変更
                      // var rows = statusRows.filter(row => row.text === newData.Status);
                      // if(rows.length > 0){
                      //   var value = rows[0].value;
                      //   newData.Status = value;
                      // }

                      // 未設定の時は初期値をセット
                      if (newData.MeasuresStartDateTime === undefined || newData.MeasuresStartDateTime === null) {
                        var startDate = new Date(prevState.workDay);
                        startDate.setHours(7, 0, 0, 0);
                        var startDateTimeString = format(startDate, 'yyyy/MM/dd HH:mm:ss', { locale: ja });
                        newData.MeasuresStartDateTime = startDateTimeString;
                      }
                      if (newData.MeasuresEndDateTime === undefined || newData.MeasuresEndDateTime === null) {
                        var endDate = new Date(prevState.workDay);
                        endDate.setHours(19, 0, 0, 0);
                        var endDateTimeString = format(endDate, 'yyyy/MM/dd HH:mm:ss', { locale: ja });
                        newData.MeasuresEndDateTime = endDateTimeString;
                      }
                      if (newData.Status === undefined || newData.Status === null) {
                        var status = statusRows.filter(row => row.text === "未確定");
                        if (status.length > 0) {
                          newData.Status = status[0].value;
                        }
                      }

                      data[index] = newData;

                      setErrorMessage("");
                      if (newData.Id !== undefined && newData.Id !== null) {
                        // 更新処理
                        measuresResultsService.modifyMeasuresResults(newData)
                          .then(data => {
                            // 非同期処理
                            if (data !== undefined && data.response !== undefined && data.response.status !== undefined && data.response.status != 200) {
                              // 正常応答以外
                              setErrorMessage("エラーが発生しました。入力内容に問題があります。[" + data.response.statusText + data.message + "]");
                            }
                            else if (data === "") {
                              // 正常応答
                              alert("更新が完了しました。" + newData.MeasuresName);
                            }
                          });
                      }
                      else {
                        // 追加処理
                        measuresResultsService.addMeasuresResults(newData)
                          .then(res => {
                            // 非同期処理
                            if (res !== "") {
                              // 異常応答
                              if (res !== undefined && res.response !== undefined && res.response.status !== undefined && res.response.status != 200) {
                                // 正常応答以外
                                setErrorMessage("エラーが発生しました。入力内容に問題があります。[" + res.response.statusText + res.message + "]");
                              }
                              else {
                                // IDを反映
                                if (!res.isAxiosError) {
                                  data[data.length - 1].Id = res;
                                  alert("追加が完了しました。" + newData.MeasuresName);
                                }
                              }
                            }
                          });
                      }

                      return { ...prevState, data };
                    });
                  }, process.env.REACT_APP_TIMEOUT);
                }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    setState(prevState => {
                      const index = prevState.data.indexOf(oldData);
                      const data = [...prevState.data];
                      data.splice(index, 1);

                      setErrorMessage("");

                      // 削除処理
                      measuresResultsService.removeMeasuresResults(oldData.Id)
                        .then(data => {
                          // 非同期処理
                          if (data !== undefined && data.response !== undefined && data.response.status !== undefined && data.response.status != 200) {
                            // 正常応答以外
                            setErrorMessage("エラーが発生しました。[" + data.response.statusText + data.message + "]");
                          }
                          else if (data === "") {
                            // 正常応答

                            alert("削除が完了しました。" + oldData.MeasuresName);
                          }
                        });
                      return { ...prevState, data };
                    });
                  }, process.env.REACT_APP_TIMEOUT);
                })
            }
            :
            // 更新追加削除不可
            {}
        }
      >
      </MaterialTable>
      {/* エラーメッセージ */}
      <Grid className={classes.errorText}>{errorMessage}</Grid>
    </Grid>
  )
};

DailySituationTable.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  //columns:PropTypes.object,
  datas: PropTypes.array.isRequired,
  detailColumns: PropTypes.object,
  detailDatas: PropTypes.array,
  templateDatas: PropTypes.array,
  ecsServiceDatas: PropTypes.array,
  workdate: PropTypes.object,
  isDetailChanged: PropTypes.bool,
  isFutureday: PropTypes.bool,
};


//#region CalendarDailySituationList
/**施策カレンダーメイン処理 */
function CalendarDailySituationList(props) {
  //#region 定数
  const classes = useStyles();
  const router = useRouter();
  const [selectCalendar, setSelectCalendar] = React.useState(currentCalendar);
  //const [today, setToday] = React.useState(new Date());
  const [workDay, setWorkDay] = React.useState(new Date());
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isChanged, setIsChanged] = React.useState(false);
  const [isDetailChanged, setIsDetailChanged] = React.useState(false);
  const [isFutureday, setIsFutureday] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState('');

  //#endregion 定数

  // #region 変数
  //var initialState = [];

  // テンプレート
  let isLoadingTemplates = true;
  const [templateRows, setTemplateRows] = React.useState([]);

  // ECSサービス
  let isLoadingEcsServices = true;
  const [ecsServiceRows, setEcsServiceRows] = React.useState([]);

  // 施策実績データ
  const defaultMeasuresResultsRows = [
    {
      Id: null, MeasuresStartDateTime: null, MeasuresEndDateTime: null, MeasuresName: null, Status: null,
      EcsServiceList: [
        { Id: null, EcsServiceName: null, EcsClusterName: null, TaskNum: 0 },
      ]
    }
  ];
  let isLoadingMeasuresResults = true;
  const [measuresResultsRows, setMeasuresResultsRows] = React.useState([
    //[...measuresResultsRows1]

  ]);

  // メモ
  let isLoadingMemos = true;
  const [memo, setMemo] = React.useState(createMemo());

  // CloudWatchMetrics
  let isLoadingSrcImage = true;
  const [srcImage1, setSrcImage1] = React.useState('data:image/png;base64,');
  const [srcImage2, setSrcImage2] = React.useState('data:image/png;base64,');
  const [srcImage3, setSrcImage3] = React.useState('data:image/png;base64,');

  const [srcUrl1, setSrcUrl1] = React.useState('data:image/png;base64,');
  const [srcUrl2, setSrcUrl2] = React.useState('data:image/png;base64,');
  const [srcUrl3, setSrcUrl3] = React.useState('data:image/png;base64,');

  // #endregion 変数

  //#region イベント

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenu1Close = () => {
    setAnchorEl(null);
    router.push('/TemplateMaintenance');
  };

  const handleMenu2Close = () => {
    setAnchorEl(null);
    router.push('/CalendarDailySituationList');
  };

  /** 対象年月を変更したときに呼ばれる */
  const handleCalenderYmChange = (event, child) => {
    setSelectCalendar(event.target.value);
    var date = new Date(child.key);
    date.setHours(0, 0, 0, 0);
    setWorkDay(date);
    setSelectedIndex(0);

    var now = new Date();
    now.setHours(0, 0, 0, 0);
    setIsFutureday(date >= now ? true : false);
    //console.log(date);
    calendarRows = [...createDateList(date)];
  };

  /** メモを変更したときに呼ばれる */
  const handleMemoChange = (event) => {
    var destMemo = memo;
    setIsChanged(true);
    destMemo.Memo = event.target.value;
    setMemo(destMemo);
  }

  /** メモを更新 */
  const handleMemoSaveClick = (event) => {
    var d = workDay;
    var srcMemo = memo;
    if (isChanged) {
      setErrorMessage("");

      memosService.modifyMemos(srcMemo).then(data => {
        // 非同期処理
        if (data !== undefined && data.response !== undefined && data.response.status !== undefined && data.response.status != 200) {
          // 正常応答以外
          setErrorMessage("エラーが発生しました。[" + data.response.statusText + data.message + "]");
        }
        else if (data === "") {
          // 正常応答
          var date = format(workDay, 'yyyy/MM/dd', { locale: ja });
          alert("メモ欄を更新しました。" + date);
        }
      });

    }
  }

  /** カレンダーリストアイテム変更 */
  function handleCalendarItemClick(event, index) {
    var destSelectedIndex = selectedIndex;
    var selectDate = calendarRows[index];
    if (selectDate.workDate === '' || selectDate.dateString === "----------") {

      console.log("selectedIndex:" + selectedIndex);
      setSelectedIndex(0);
      var date = new Date(calendarRows[0].workDate);
      return;
    }

    console.log(selectDate.isFutureday);
    setIsFutureday(selectDate.isFutureday);
    setSelectedIndex(index);
    var date = new Date(selectDate.workDate);
    setWorkDay(date);
    isLoadingMeasuresResults = true;
    isLoadingMemos = true;
    isLoadingSrcImage = true;
    setIsLoading(true);

    console.log("selectDate:" + selectDate);
  };

  const getAllTempletes = () => {
    templatesService.getAllTempletes()
      .then(data => {
        // 非同期で呼ばれるため
        var result = [];
        if (Array.isArray(data)) {
          // 配列
          result.push(data);
        } else {
          // 配列以外
          result = data;
        }

        // 応答結果をuseStateにセット
        var json = JSON.stringify(result[0]);
        //console.log(json);
        var response = JSON.parse(json);

        // テンプレートをセットする
        setTemplateRows([...response]);

        // ローディングフラグをクリア
        isLoadingTemplates = false;
        if (!isLoadingTemplates &&
          !isLoadingEcsServices &&
          !isLoadingMeasuresResults &&
          !isLoadingMemos ) {
          // 読込完了
          setIsLoading(false);
        }
      });
  };
  const getAllEcsServices = () => {
    ecsServicesService.getAllEcsServices()
      .then(data => {
        // 非同期で呼ばれるため
        var result = [];
        if (Array.isArray(data)) {
          // 配列
          result.push(data);
        } else {
          // 配列以外
          result = data;
        }

        // 応答結果をuseStateにセット
        var json = JSON.stringify(result[0]);
        //console.log(json);
        var response = JSON.parse(json);

        // ECSサービスをセットする
        setEcsServiceRows([...response]);

        // ローディングフラグをクリア
        isLoadingEcsServices = false;
        if (!isLoadingTemplates &&
          !isLoadingMeasuresResults &&
          !isLoadingMemos ) {
          // 読込完了
          setIsLoading(false);
        }
      });
  };
  const getTargetDateMeasuresResults = (date) => {
    measuresResultsService.getTargetDateMeasuresResults(date)
      .then(data => {
        // 非同期で呼ばれるため
        var result = [];
        if (Array.isArray(data)) {
          // 配列
          result.push(data);
        } else {
          // 配列以外
          result = data;
        }

        // 応答結果をuseStateにセット
        var json = JSON.stringify(result[0]);
        //console.log(json);
        var response = JSON.parse(json);

        // 施策実績データをセットする
        setMeasuresResultsRows([...defaultMeasuresResultsRows]);
        if (result[0].length !== 0) {
          response.forEach(elm => {
            // var rows = statusRows.filter(row => row.value === elm.Status);
            // if(rows.length > 0) {
            //   elm.Status = rows[0].text;
            // }
          });
          setMeasuresResultsRows([...response]);
        }

        // ローディングフラグをクリア
        isLoadingMeasuresResults = false;
        if (!isLoadingTemplates && !isLoadingEcsServices && !isLoadingMemos) {
          // 読込完了
          setIsLoading(false);
        }
      });
  };

  /** 初期化・更新・クリーンアップ時 */
  React.useEffect(() => {
    // 初期処理
    if (isLoading) {
      // 作業日
      workDay.setHours(0, 0, 0, 0);
      var date = format(workDay, 'yyyyMMdd', { locale: ja });
      setWorkDay(workDay);
      // console.log("workDay:" + date);
      //var date1 = new Date(workDay.getTime());
      var item = calendarRows.filter(row => new Date(row.workDate).getTime() === workDay.getTime());
      if (item.length > 0) {
        setSelectedIndex(item[0].id - 1);
      }

      // テンプレート
      if (isLoadingTemplates) {
        getAllTempletes();
      }

      // ECSサービス
      if (isLoadingEcsServices) {
        getAllEcsServices();
      }

      // 施策実績データ
      if (isLoadingMeasuresResults) {
        getTargetDateMeasuresResults(date);
      }

      if (isLoadingMemos) {
        // メモ
        memosService.getMemos(date)
          .then(data => {
            // 非同期で呼ばれるため
            var result = [];
            if (Array.isArray(data)) {
              // 配列
              result.push(data);
            } else {
              // 配列以外
              result = data;
            }

            // 応答結果をuseStateにセット
            var json = JSON.stringify(result);
            //console.log(json);
            var response = JSON.parse(json);

            // メモをセットする
            setMemo(response);

            // ローディングフラグをクリア
            isLoadingMemos = false;

            if (!isLoadingTemplates &&
              !isLoadingEcsServices &&
              !isLoadingMeasuresResults) {
              //読込完了
              setIsLoading(false);
            }
          });
      }

      // CloudWatchMetrics
      if (isLoadingSrcImage) {
        // 該当日のCloudWatchの画像を取得
        cloudwatchMetricsService.getImages(date, date).then(data => {
          setSrcImage1('data:image/png;base64,' + data[0].MetricWidgetImage);
          setSrcImage2('data:image/png;base64,' + data[1].MetricWidgetImage);
          setSrcImage3('data:image/png;base64,' + data[2].MetricWidgetImage);


          // 該当日のCloudWatchのUrlを取得
          cloudwatchMetricsService.getUrls(date, date).then(data => {
            setSrcUrl1(data[0].Url);
            setSrcUrl2(data[1].Url);
            setSrcUrl3(data[2].Url);
          });
          // ローディングフラグをクリア
          isLoadingSrcImage = false;
          if (!isLoadingTemplates &&
            !isLoadingEcsServices &&
            !isLoadingMeasuresResults &&
            !isLoadingMemos) {
            //読込完了
            setIsLoading(false);
          }
        });
      }
    }

    return () => {
      // TODO:クリーンアップ処理を書く
    };
  }, [isLoading]);
  //#endregion イベント

  return (
    <ThemeProvider theme={lightTheme}>
      <Container className={classes.root}>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
          メニュー
      </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenu1Close}>施策テンプレート保守</MenuItem>
          <MenuItem onClick={handleMenu2Close}>施策カレンダー</MenuItem>
        </Menu>
        {isLoading ?
          (
            // 処理中
            <CircularProgress />
          )
          :
          (
            <Grid container justify="flex-start">
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography variant="h5" component="h3">施策カレンダー</Typography>
                </Paper>
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <InputLabel>対象年月：</InputLabel>
                <Select
                  labelId="service-select-label"
                  id="service-select"
                  value={selectCalendar}
                  onChange={(event, child) => handleCalenderYmChange(event, child)}
                >
                  {calendarYmRows.map((cal) => {
                    return (
                      <MenuItem key={cal.workDate} value={cal.displayString}>
                        {cal.displayString}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={2}>
                <List className={classes.list}
                  dense
                  labelId="calendar-select-label"
                  id="calendar-select"
                >
                  {calendarRows.map((cal, index) => {
                    return (
                      <ListItem className={classes.listItem}
                        button dense divider
                        key={index}
                        selected={selectedIndex === index}
                        onClick={event => handleCalendarItemClick(event, index)}>
                        <ListItemText primary={calendarRows[index].dateString} className={classes.listItemText}
                        />
                      </ListItem>);
                  })}
                </List>
              </Grid>
              <Divider />
              <Grid item xs={10}>
                <Grid>
                  <TextField type='text' value={format(workDay, 'yyyy/MM/dd (EEE)', { locale: ja })} defaultValue={format(workDay, "yyyy/MM/dd (EEE)", { locale: ja })} />
                </Grid>
                <Divider />
                <Grid>
                  <div className={classes.imageOver}>
                    <a target="_blank" href={srcUrl1}>
                      <img src={srcImage1} width='300' height='200' alt="image1" style={{ border:'1px black inset'}}/>
                    </a>
                  </div>
                  <div className={classes.imageOver}>
                    <a target="_blank" href={srcUrl2}>
                      <img src={srcImage2} width='300' height='200' alt="image2" style={{ border: '1px black inset' }}/>
                    </a>
                  </div>
                  <div className={classes.imageOver}>
                    <a target="_blank" href={srcUrl3}>
                      <img src={srcImage3} width='300' height='200' alt="image3" style={{ border: '1px black inset'}}/>
                    </a>
                  </div>
                </Grid>
                <div className={classes.imageOverEnd} />
                <Divider />

                <Grid item  >
                  <InputLabel >メモ欄</InputLabel>
                  <TextField variant="outlined" className={classes.textArea}
                    fullWidth
                    multiline={true}
                    rows={4}
                    rowsMax={4}
                    defaultValue={memo.Memo}
                    onChange={event => handleMemoChange(event)}
                  />
                </Grid>
                <Box display={
                  // 編集モードの切替
                  isFutureday ?
                    // 表示
                    { md: 'block' }
                    :
                    // 非表示
                    { md: 'none' }
                } m={1}>
                  <Button variant="outlined" color="secondary" onClick={event => handleMemoSaveClick(event)}>更新</Button>
                </Box>
                <Divider />
                <Grid>
                  <DailySituationTable
                    classes={classes}
                    options={options}
                    datas={measuresResultsRows}
                    detailColumns={detailColumns}
                    detailDatas={measuresResultsRows[0].EcsServiceList === 'undefined' ? [] : measuresResultsRows[0].EcsServiceList}
                    templateDatas={templateRows}
                    ecsServiceDatas={ecsServiceRows}
                    workdate={workDay}
                    isDetailChanged={isDetailChanged}
                    isFutureday={isFutureday}
                  />
                </Grid>
                <Divider />
              </Grid>
              <Divider />
            </Grid>

          )
        }

      </Container>
    </ThemeProvider>

  );
}

export default CalendarDailySituationList;
//#endregion CalendarDailySituationList
