import axios from "axios";

// 施策実績データ

/** 日付指定取得 */
export async function getTargetDateMeasuresResults(date){
  var params = '?targetDate=' + date;
  var url = process.env.REACT_APP_URL_MEASURES_RESULTS + params;
  console.log("url:"+ url);
    const result = await axios.get(url)
      .then(response => {
        // if(response.data === null || response.data.length === 0){
        //     return '[{"Id":null,"MeasuresStartDateTime":null,"MeasuresEndDateTime":null,"MeasuresName":null,"Status":null,"EcsServiceList":[{"Id":null,"EcsServiceName":null,"EcsClusterName":null,"TaskNum":null}]}]';
        // }
        return response.data;
      })
      .catch(error => {
        console.log(error);
        return error;
      });
        console.log(result);
    return result;
  }

/** １件取得　*/
export async function getMeasuresResults(id){
  var params = '/' + id;
  var url = process.env.REACT_APP_URL_MEASURES_RESULTS + params;
  console.log("url:"+ url);
  const result = await axios.get(url)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log(error);
        return error;
      });
        console.log(result);
    return result;
  }

const headers =  {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",};

/** 追加 */
export async function addMeasuresResults(measuresResults){
  var data = measuresResults;
  var params = '';
  var url = process.env.REACT_APP_URL_MEASURES_RESULTS + params;
  console.log("url:"+ url);
  const result = await axios.post(url,data,headers)
      .then(response => {
        // 正常応答以外
        if(response.status != 200){
          console.log(response);
        }
        return response.data;
      })
      .catch(error => {
        console.log(error);
        return error;
      });
        console.log(result);
    return result;
  }

/** 更新 */
export async function modifyMeasuresResults(measuresResults){
    var data = measuresResults;
    var params = '/' + measuresResults.Id;
    var url = process.env.REACT_APP_URL_MEASURES_RESULTS + params;
    console.log("url:"+ url);
    const result = await axios.put(url,data,headers)
      .then(response => {
        // 正常応答以外
        if(response.status != 200){
          console.log(response);
        }
        return response.data;
      })
      .catch(error => {
        console.log(error);
        return error;
      });
        console.log(result);
    return result;
  }

/** 削除 */
export async function removeMeasuresResults(id){
  var params = '/' + id;
  var url = process.env.REACT_APP_URL_MEASURES_RESULTS + params;
  console.log("url:"+ url);
  const result = await axios.delete(url)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log(error);
        return error;
      });
        console.log(result);
    return result;
  }
