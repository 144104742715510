import axios from "axios";

// テンプレート

/** 全件取得 */
export const getAllTempletes = async () => {
  var params = '';
  var url = process.env.REACT_APP_URL_TEMPLATES  + params;
  console.log("url:"+ url);
  const result = await axios.get(url)
  .then(response => {
    return response.data;
  })
  .catch(error => {
    console.log(error);
    return error;
  });
return result;
}

/** Ｎ件取得　*/
export const getTempletes = async (Id) => {
  var params = Id
  var url = process.env.REACT_APP_URL_TEMPLATES  + "/" + params;
  console.log("url:"+ url);
  const result = await axios.get(url)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
      return error;
    });
  return result;
}

/** 追加 */
export const addTempletes = async (templete) => {
  var params = '';
  var data = templete;
  var url = process.env.REACT_APP_URL_TEMPLATES  + params;
  console.log("url:"+ url);
  const result = await axios.post(url,data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
      return error;
    });
  console.log(result);
  return result;
}

/** 更新 */
export const modifyTempletes = async (id, templete) => {
  var params = id;
  var data = templete;
  var url = process.env.REACT_APP_URL_TEMPLATES  + "/" + params;
  console.log("url:"+ url);
  const result = await axios.put(url,data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
      return error;
    });
  console.log(result);
  return result;
}

/** 削除 */
export const removeTempletes = async (Id) => {
  var params = Id
  var url = process.env.REACT_APP_URL_TEMPLATES  + "/" + params;
  console.log("url:"+ url);
  const result = await axios.delete(url)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
      return error;
    });
  console.log(result);
  return result;
}

// const templatesServiceWorker = 'templatesServiceWorker';
// export default templatesServiceWorker ;

