import axios from "axios";

// ECSサービス

// 全件取得
export async function getAllEcsServices(){
  var params = "";
  var url = process.env.REACT_APP_URL_ECS_SERVICES+ params;
  console.log("url:"+ url);
  const result = await axios.get(url)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
      return error;
    });
    console.log(result);
    return result;
}

/** １件取得　*/
export async function getEcsServices(id){
  var params = "/" + id;
  var url = process.env.REACT_APP_URL_ECS_SERVICES + params;
  console.log("url:"+ url);
  const result = await axios.get(url)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
      return error;
    });
  console.log(result);
  return result;
}

/** 追加 */
export async function addEcsServices(ecsService){
  var params =  "";
  var data = ecsService;
  var url = process.env.REACT_APP_URL_ECS_SERVICES + params;
  console.log("url:"+ url);
  const result = await axios.post(url,data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
      return error;
    });
  console.log(result);
  return result;
}

/** 更新 */
export async function modifyEcsServices(id,ecsService){
  var params = "/" + id;
  var data = ecsService;
  var url = process.env.REACT_APP_URL_ECS_SERVICES + params;
  console.log("url:"+ url);
  const result = await axios.put(url,data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
      return error;
    });
  console.log(result);
  return result;
}

/** 削除 */
export async function removeEcsServices(id){
  var params = "/" + id;
  const result = await axios
    .delete(process.env.REACT_APP_URL_ECS_SERVICES + params)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
      return error;
    });
    console.log(result);
    return result;
}
