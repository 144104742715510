import axios from "axios";

// CloudWatchMetrics
function GetHeadres(){
  return "";
}

/** 全件取得 */
export async function getAllUrls(){
  const result = await axios
    .get(process.env.REACT_APP_URL_CLOUDWATCH_METRICS)
    .then(response => {
      return response.data;
    })
    .catch(error => console.log(error.response.status));
    console.log(result);
  return result;
}

/** 全件取得 */
export async function getAllImages(){
  const result = await axios
    .get(process.env.REACT_APP_URL_CLOUDWATCH_METRICS + '/images')
    .then(response => {
      return response.data;
    })
    .catch(error => console.log(error.response.status));
    console.log(result);
  return result;
}

/** パラメータ指定で取得*/
/* 画像データ取得*/
export async function getImages(targetDateFrom, targetDateTo){
  let images = 'hhhh'
  try {
     const timeFrom = targetDateFrom + '000000';
     const timeTo = targetDateTo + '235959';
     const params = { targetDateFrom : timeFrom, targetDateTo : timeTo };
     
     const url = process.env.REACT_APP_URL_CLOUDWATCH_METRICS + '/images?targetDateFrom=' + timeFrom + '&targetDateTo=' + timeTo;
     
     console.log(url);
     
     const res = await axios.get(url);
     var stringData = JSON.stringify(res.data);
     var JsonData = JSON.parse(stringData);
     images = JsonData.MetricWidgetImage;
     
     return JsonData;
  } catch (error) {
     //const { status, statusText } = error;
     console.log(error.status);
     return error.message;
  }
}

/* 画像リンクurl取得 */
export async function getUrls(targetDateFrom, targetDateTo){
  let urls = 'hhhh'
  try {
     const timeFrom = targetDateFrom + '000000';
     const timeTo = targetDateTo + '235959';
     const params = { targetDateFrom : timeFrom, targetDateTo : timeTo };
     
     const url = process.env.REACT_APP_URL_CLOUDWATCH_METRICS + '/urls?targetDateFrom=' + timeFrom + '&targetDateTo=' + timeTo;
     
     console.log(url);
     
     const res = await axios.get(url);
     var stringData = JSON.stringify(res.data);
     var JsonData = JSON.parse(stringData);
     urls = JsonData.MetricWidgetImage;
     
     return JsonData;
  } catch (error) {
     //const { status, statusText } = error;
     console.log(error.status);
     return error.message;
  }
}